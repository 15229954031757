import { IncomingAction, IncomingEventType } from '../types';
import { validateUpdateButtonVisibilityType } from '../validate-type';

export interface ButtonState {
  display?: boolean;
  shouldDisabled: boolean;
  isLoading?: boolean;
}

export const reducer = (
  state: ButtonState,
  action: IncomingAction<IncomingEventType>,
): ButtonState => {
  if (validateUpdateButtonVisibilityType(action)) {
    return action.payload;
  }
  return state;
};
