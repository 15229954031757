import { Address } from '../types';

export const optumDateFormatter = (date: string): string => {
  const [year, day, month] = date.split('-');

  return `${day}-${month}-${year}`;
};

export const getFullAddress = ({ address1, address2, city, state, zipCode }: Address): string => {
  return [address1, address2, city, state, zipCode].filter((item) => !!item).join(', ');
};
