import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIsAppOpenInHub } from '@getvim/components-hooks-vim-connect';
import { ModuleNames } from '@getvim/vim-app-infra';
import {
  useAppEventsHandler,
  useNotifications,
  useModifyAppViewState,
  useWriteBack,
} from '../../hooks';
import { OptumAnalyticsClient } from '../../analytics';
import OptumError from '../optumError/OptumError';
import {
  ApplicationLaunchType,
  GareGaps,
  GareGapsRequest,
  LaunchUrlRequest,
  SelectedApplicationTitle,
  WritebackUrlRequest,
  EhrWritebackDiagnosis,
  StatusType,
} from '../../types';
import { getFullAddress } from '../../utils/formatter';
import { useFeatureFlag } from '@getvim/feature-flags-react';
import SimpleLoading from '../loading/SimpleLoading';
import './generic-widget.css';

const DATA_CORE_SUBMIT_EVENT = 'datacore-submit';
const STORAGE_AUTOMATION_DIAGNOSIS = 'vim_automation_diagnosis';
const STORAGE_SHOULD_SIMULATE_DATA_CORE_EVENT = 'vim_should_simulate_data_core_submit_event'

const initialState = {
  url: undefined,
  cacheKey: undefined,
  resultsError: false,
  isLoading: false,
  title: undefined,
};

interface GenericWidgetProps {
  widgetId: ModuleNames;
  getLaunchUrl: ({
    patientId,
  }: LaunchUrlRequest) => Promise<{ url: string; cacheKey?: string } | undefined>;
  getCareGaps?: (data: GareGapsRequest) => Promise<GareGaps | undefined>;
  getWriteback?: ({ cacheKey }: WritebackUrlRequest) => Promise<any | undefined>;
  launchType?: ApplicationLaunchType;
  injectNotifications?: boolean;
}

const GenericWidget = (props: GenericWidgetProps) => {
  const { getLaunchUrl, widgetId, launchType, injectNotifications, getCareGaps, getWriteback } =
    props;
  const [handleModifyAppView] = useModifyAppViewState();
  const isAppOpen = useIsAppOpenInHub(widgetId);
  const { patient, encounter } = useAppEventsHandler();
  const notifications = useNotifications();
  const { updateEncounter, updateProblemList } = useWriteBack();
  const analyticsClient = useMemo(() => new OptumAnalyticsClient(widgetId), [widgetId]);

  const [launchUrl, setLaunchUrl] =
    useState<{
      url: string | undefined;
      cacheKey: string | undefined;
      resultsError: boolean;
      isLoading: boolean;
      title: string | undefined;
    }>(initialState);
  const [shouldUpdateLaunchUrl, setShouldUpdateLaunchUrl] = useState<boolean>(true);
  const [shouldSimulateDataCoreSubmitEvent] = useFeatureFlag({
    flagName: 'shouldSimulateDataCoreSubmitForStagingOnly',
    defaultValue: false,
  });

  useEffect(() => {
    localStorage.setItem(STORAGE_SHOULD_SIMULATE_DATA_CORE_EVENT, shouldSimulateDataCoreSubmitEvent.toString());
  }, [shouldSimulateDataCoreSubmitEvent]);

  const getWritebackData = useCallback(
    async (cacheKey) => {
      const emptyResult = { diagnosis: [] };

      if (shouldSimulateDataCoreSubmitEvent) {
        return JSON.parse(localStorage.getItem(STORAGE_AUTOMATION_DIAGNOSIS) || `${emptyResult}`);
      }

      return getWriteback ? getWriteback({ cacheKey: cacheKey! }) : emptyResult;
    },
    [getWriteback, launchUrl, shouldSimulateDataCoreSubmitEvent]
  )

  const writeBackDiagnosis = useCallback(
    async (message) => {
      const { data } = message || {};

      if (data === DATA_CORE_SUBMIT_EVENT && getWriteback) {
        const { cacheKey } = launchUrl;

        const { diagnosis } = await getWritebackData(cacheKey);

        const encounterData: EhrWritebackDiagnosis[] = [];
        const problemListData: EhrWritebackDiagnosis[] = [];

        diagnosis.forEach(({ status, code, description }) => {
          status === StatusType.Agree
            ? encounterData.push({ code, description, note: 'New diagnosis added by DataCORE' })
            : problemListData.push({ code, description });
        });

        if (encounterData.length) {
          await updateEncounter(encounterData);
        }

        if (problemListData.length) {
          await updateProblemList(problemListData);
        }
      }
    },
    [getWriteback, launchUrl, updateEncounter, updateProblemList],
  );

  useEffect(() => {
    if (widgetId === ModuleNames.OptumDataCoreWidget) {
      window.addEventListener('message', writeBackDiagnosis);
    }
    return () => window.removeEventListener('message', writeBackDiagnosis);
  }, [launchUrl, widgetId, writeBackDiagnosis]);

  const getLaunchRequestPayload = useCallback(() => {
    let encounterData = {};
    if (encounter) {
      encounterData = {
        providerId: encounter.provider.providerEhrId,
        encounterId: encounter.encounterId,
        encounterDateTime: encounter.encounterDate,
      };
    }

    switch (widgetId) {
      /** OptumProWidget */
      case ModuleNames.OptumProWidget: {
        const {
          patientId,
          demographics: { address, dateOfBirth, firstName, lastName },
        } = patient!;

        return {
          patientId,
          address: getFullAddress(address),
          dateOfBirth,
          firstName,
          lastName,
        };
      }
      /** OptumDataCoreWidget */
      case ModuleNames.OptumDataCoreWidget: {
        const {
          patientId,
          demographics: { dateOfBirth, firstName, lastName },
          insurance: { memberId, groupId },
          problemList,
        } = patient!;

        return {
          patientId,
          dateOfBirth,
          firstName,
          lastName,
          launchType,
          subscriberNumber: memberId,
          groupNumber: groupId,
          problemList,
          ...encounterData,
        };
      }

      /** OptumDashboardWidget */
      case ModuleNames.OptumDashboardWidget: {
        return {
          launchType,
        };
      }
      default: {
        return {};
      }
    }
  }, [launchType, widgetId, patient, encounter]);

  const getWidgetLaunchUrl = useCallback(async () => {
    if (!patient && widgetId !== ModuleNames.OptumDashboardWidget) return;

    setLaunchUrl({
      url: undefined,
      cacheKey: undefined,
      isLoading: true,
      resultsError: false,
      title: SelectedApplicationTitle[widgetId],
    });

    const payload = getLaunchRequestPayload();

    const response = await getLaunchUrl(payload);
    if (!response) {
      analyticsClient.trackLaunchUrlError();
    } else {
      analyticsClient.trackLaunchUrlLoaded();
    }
    setLaunchUrl({
      url: response?.url,
      cacheKey: response?.cacheKey,
      isLoading: false,
      resultsError: !response,
      title: SelectedApplicationTitle[widgetId],
    });
    setShouldUpdateLaunchUrl(false);
  }, [getLaunchRequestPayload, analyticsClient, getLaunchUrl, widgetId, patient]);

  /**
   * Trigger launch request at first time
   */
  useEffect(() => {
    if (isAppOpen && shouldUpdateLaunchUrl) {
      getWidgetLaunchUrl();
    }
  }, [isAppOpen, getWidgetLaunchUrl, shouldUpdateLaunchUrl]);

  /**
   * Trigger launch request after the patient or the encounter will change
   */
  useEffect(() => {
    if (!isAppOpen && launchUrl.resultsError) {
      setShouldUpdateLaunchUrl(true);
    }
    setShouldUpdateLaunchUrl(true);
  }, [patient, encounter]);

  /**
   * Trigger launch request after reopening the app which contains error
   */
  useEffect(() => {
    if (!isAppOpen && launchUrl.resultsError) {
      setShouldUpdateLaunchUrl(true);
    }
  }, [isAppOpen]);

  useEffect(() => {
    const getPatientCareGaps = async () => {
      if (!injectNotifications || !patient) return;
      const {
        patientId,
        demographics: { dateOfBirth, firstName, lastName },
      } = patient!;

      let patientCareGaps;

      if (getCareGaps) {
        patientCareGaps = await getCareGaps({
          patientId,
          dateOfBirth,
          firstName,
          lastName,
        });
      }

      if (patientCareGaps?.total) {
        notifications.patientGaps(patientCareGaps);

        await handleModifyAppView({
          enable: true,
          modifications: { notifications: patientCareGaps?.total },
        });
      }
    };

    if (patient) {
      getPatientCareGaps();
    }
  }, [patient, injectNotifications, getCareGaps]);

  /**
   * Trigger closing widget, should be enabled after the closing
   */
  const onClose = async () => {
    await handleModifyAppView({
      enable: false,
    });
    await handleModifyAppView({
      enable: true,
    });
  };

  const { url, resultsError, isLoading, title } = launchUrl;

  return (
    <div className="generic-widget-container">
      <button className="simulate-datacore-submit-event-btn" onClick={() => window.postMessage(DATA_CORE_SUBMIT_EVENT)}></button>
      <div className="generic-widget-title">{title}</div>
      <div className="frame-wrapper">
        {isLoading && <SimpleLoading />}
        {resultsError && <OptumError onClose={onClose} handleClick={getWidgetLaunchUrl} />}
        {url && <iframe className="frame" src={url} />}
      </div>
    </div>
  );
};

export default GenericWidget;
