import React from 'react';
import { ReactComponent as OptumErrorImg } from './optum-error.svg';
import './optum-error.css';

interface OptumErrorProps {
  handleClick: () => void;
  onClose: () => void;
}

const OptumError: React.FC<OptumErrorProps> = ({ handleClick, onClose }) => {
  return (
    <div className="error-iframe">
      <OptumErrorImg />
      <div className="error-iframe-title">Ooops...</div>
      <div className="error-iframe-text">something went wrong</div>
      <div className="error-iframe-buttons-container">
        <button className="error-iframe-resubmit-button" type="button" onClick={handleClick}>
          Try Again
        </button>
        <button className="error-iframe-cancel-button" type="button" onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  );
};

export default OptumError;
