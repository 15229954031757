import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ModuleNames, VimCommunicationProvider } from '@getvim/vim-app-infra';
import SelectProviderInlineWidget from './components/inlineWidget/select-provider-inline-widget';
import SearchProviderWidgetInVimHub from './components/searchProviderWidgetInVimHub/search-provider-widget';
import GenericWidget from './components/genericWidget/generic-widget';
import { ApplicationLaunchType } from './types';
import '@getvim/atomic-ui/assets/styles/main.less';

import { apiClient } from './api';

function App() {
  return (
    <Router basename={import.meta.env.BASE_URL}>
      <Switch>
        <Route path="/select-provider-inline-widget">
          <SelectProviderInlineWidget />
        </Route>
        <Route path="/search-provider-widget">
          <VimCommunicationProvider widgetId={ModuleNames.OptumReferralUtilityWidget}>
            <SearchProviderWidgetInVimHub />
          </VimCommunicationProvider>
        </Route>
        <Route path="/pro-widget">
          <VimCommunicationProvider widgetId={ModuleNames.OptumProWidget}>
            <GenericWidget
              widgetId={ModuleNames.OptumProWidget}
              getLaunchUrl={apiClient.getOptumProLaunchUrl}
            />
          </VimCommunicationProvider>
        </Route>
        <Route path="/data-core-widget">
          <VimCommunicationProvider widgetId={ModuleNames.OptumDataCoreWidget}>
            <GenericWidget
              widgetId={ModuleNames.OptumDataCoreWidget}
              getLaunchUrl={apiClient.getOptumDataCoreLaunchUrl}
              getCareGaps={apiClient.getOptumDataCoreCareGaps}
              getWriteback={apiClient.getOptumDataCoreWriteback}
              launchType={ApplicationLaunchType.DataCore}
              injectNotifications
            />
          </VimCommunicationProvider>
        </Route>
        <Route path="/optum-data-core-dashboard">
          <VimCommunicationProvider widgetId={ModuleNames.OptumDashboardWidget}>
            <GenericWidget
              widgetId={ModuleNames.OptumDashboardWidget}
              getLaunchUrl={apiClient.getOptumDataCoreDashboardLaunchUrl}
              launchType={ApplicationLaunchType.Dashboard}
            />
          </VimCommunicationProvider>
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
