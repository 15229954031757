import {
  ActionButtonAction,
  BtnType,
  registerVimConnectUIWidget,
  sendEventToAppVimConnectUIWidget,
} from '@getvim/utils-vim-connect-communication';
import { Entities } from '@getvim/vim-connect';
import { ModuleNames } from '@getvim-communication/management';
import { Notifications } from '@getvim/vim-connect-communication';
import React, { FC, useEffect, useReducer } from 'react';
import { useFeatureFlag } from '@getvim/feature-flags-react';
import { IncomingAction, IncomingEventType, Screens, OutgoingEventType } from '../../types';
import {
  validateGetUrlType,
  validateSetInitialStateType,
  validateSetScreenType,
} from '../../validate-type';
import SimpleLoading from '../loading/SimpleLoading';
import WritebackLoading from '../loading/WritebackLoading';
import './search-provider-widget.css';
import { referralUtilityWidgetId } from '../../consts';
import { ReactComponent as WriteBackErrorSVG } from './write-back-error.svg';

enum NotificationTypeEnum {
  ReferralCanBeCreatedViaApp = 'referral-is-ready-to-be-created-via-app',
}

interface State {
  url: string | undefined;
  screen: Screens;
}
const initialState: State = {
  url: undefined,
  screen: Screens.None,
};

const reducer = (state: State, action: IncomingAction<IncomingEventType>): State => {
  if ([validateGetUrlType, validateSetScreenType].some((validator) => validator(action))) {
    return { ...state, ...action.payload };
  }

  if (validateSetInitialStateType(action)) {
    return initialState;
  }

  return state;
};

function generateUniqSerial() {
  return 'xxxx-xxxx-xxx-xxxx'.replace(/[x]/g, (c) => {
    const r = Math.floor(Math.random() * 16);
    return r.toString(16);
  });
}

const SearchProviderWidgetInVimHub: FC = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const [withTransparentBackground] = useFeatureFlag({
    flagName: 'withTrasnparentBackground',
    defaultValue: false,
  });
  const { url, screen } = state;

  useEffect(() => {
    window.addEventListener('message', async (message) => {
      const { data } = message || {};
      if (data === 'referral-submit') {
        sendEventToAppVimConnectUIWidget(
          referralUtilityWidgetId,
          OutgoingEventType.ON_SUBMIT_REFERRAL,
        );
        return;
      }
      if (data === 'referral-close') {
        onClose();
        return;
      }
      if (data.type === 'vim-connect-hub-app-opened') {
        /** track when user click on vim hub app icon */
        if (data.payload?.selectedApplicationId === referralUtilityWidgetId) {
          sendEventToAppVimConnectUIWidget(
            referralUtilityWidgetId,
            OutgoingEventType.OPEN_OPTUM_REFERRAL_UTILITY,
          );
          // @ts-ignore
          Notifications.Actions.hideNotification({
            widgetId: ModuleNames.OptumReferralUtilityWidget,
          });
        } else {
          /** track when user closed vim hub app */
          dispatch({ type: IncomingEventType.SET_TO_INITIAL_STATE, payload: undefined });
          onClose();
        }
      }
      if (data.type === 'notification-on-action-button-click') {
        const {
          payload: { widgetId, action },
        } = data;
        if (widgetId === referralUtilityWidgetId) {
          switch (action) {
            case ActionButtonAction.SELECT: {
              sendEventToAppVimConnectUIWidget(
                referralUtilityWidgetId,
                OutgoingEventType.OPEN_OPTUM_REFERRAL_UTILITY,
                { expandingType: Entities.UIElements.ExpandingType.VIM_NOTIFICATION_MANUAL },
              );
              // @ts-ignore
              Notifications.Actions.hideNotification({
                widgetId: ModuleNames.OptumReferralUtilityWidget,
              });
              break;
            }
          }
        }
      }
    });
  }, []);
  const handleEvents = async (payload: { event: any; data: any }) => {
    const { event, data } = payload;
    switch (event) {
      case IncomingEventType.SET_SCREEN: {
        dispatch({ type: event, payload: data });
        break;
      }
      case IncomingEventType.GET_URL: {
        dispatch({ type: event, payload: data });
        break;
      }

      case IncomingEventType.NOTIFICATION: {
        const { type } = data;
        switch (type) {
          case NotificationTypeEnum.ReferralCanBeCreatedViaApp: {
            Notifications.Actions.showNotification({
              widgetId: ModuleNames.OptumReferralUtilityWidget,
              text: 'Use Order Utility to create your referral',
              notificationId: generateUniqSerial(),
              title: 'Order Utility',
              options: {
                timeoutInMs: 20000, // 20 sec
                vimConnectStyle: true,
                optumStyle: false,
              },
              actionButtons: [
                {
                  text: 'Open App',
                  action: ActionButtonAction.SELECT,
                  buttonType: BtnType.TINY,
                  style: { 'margin-left': 'auto' },
                },
              ],
            });
            break;
          }
        }
        break;
      }
    }
  };
  /** That's give you ability to listen events from run-time side */
  registerVimConnectUIWidget(referralUtilityWidgetId, handleEvents);

  const onClose = () => {
    sendEventToAppVimConnectUIWidget(
      referralUtilityWidgetId,
      OutgoingEventType.CLOSE_OPTUM_REFERRAL_UTILITY,
    );
    dispatch({ type: IncomingEventType.SET_TO_INITIAL_STATE, payload: undefined });
  };

  const OptumSearchProviderContent = () => {
    return <>{url ? <iframe className="widget-frame-hub" src={url} /> : <ErrorLoadIframe />}</>;
  };

  const modesDisplay = {
    [Screens.None]: <div />,
    [Screens.Loading]: (
      <div className="frame-container-hub">
        <SimpleLoading />
      </div>
    ),
    [Screens.LoadingWriteBack]: <WritebackLoading />,
    [Screens.WriteBackError]: (
      <div className="frame-container-hub">
        <ErrorWriteBackFailed onClose={onClose} />
      </div>
    ),
    [Screens.OptumSearchProvider]: (
      <div className="frame-container-hub">
        <OptumSearchProviderContent />
      </div>
    ),
  };

  if (withTransparentBackground) {
    return (
      <div
        style={{
          width: '100vw',
          height: '100vh',
          backgroundColor: 'rgba(255,255,255,0.15)',
          zIndex: -1,
        }}
      >
        <div style={{ zIndex: 10 }}>{modesDisplay[screen]}</div>
      </div>
    );
  }

  return modesDisplay[screen];
};

export default SearchProviderWidgetInVimHub;

const ErrorLoadIframe: FC = () => (
  <div className="error-load-iframe">
    <div className="error-load-iframe-title">Something went wrong :(</div>
    <div className="error-load-iframe-text">Please refresh the page and try again</div>
    <img
      className="error-load-iframe-img"
      src="https://static.getvim.com/img/errorPageImg.png"
      alt="loading logo"
    />
  </div>
);

const ErrorWriteBackFailed = ({ onClose }) => (
  <div className="error-iframe">
    <WriteBackErrorSVG />
    <div className="error-iframe-title">Ooops...</div>
    <div className="error-iframe-text">
      Failed to update the referral. <br /> Please make sure to update it manually.
    </div>
    <div className="error-iframe-buttons-container">
      <button className="error-iframe-cancel-button" type="button" onClick={onClose}>
        Close
      </button>
    </div>
  </div>
);
