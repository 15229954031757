import { Insurer } from '@getvim-os/standard';

export enum Screens {
  None = 'none',
  Loading = 'loading',
  LoadingWriteBack = 'loading-writeback',
  WriteBackError = 'writeback-error',
  OptumSearchProvider = 'optum-search-provider',
}

export enum SelectedApplicationId {
  OptumProAppId = 'optum-pro',
  OptumDataCoreAppId = 'optum-data-core',
  OptumDataCoreDashboardAppId = 'optum-data-core-dashboard',
}

export enum ApplicationTitle {
  Pro = 'Provider Reported Outcomes',
  DataCore = 'DataCORE',
  Dashboard = 'DataCORE Dashboard',
}

export enum ApplicationLaunchType {
  DataCore = 'datacore',
  Dashboard = 'dashboard',
}

export const SelectedApplicationTitle: { [name in SelectedApplicationId]?: ApplicationTitle } = {
  [SelectedApplicationId.OptumProAppId]: ApplicationTitle.Pro,
  [SelectedApplicationId.OptumDataCoreAppId]: ApplicationTitle.DataCore,
  [SelectedApplicationId.OptumDataCoreDashboardAppId]: ApplicationTitle.Dashboard,
};

export enum OutgoingEventType {
  'OPEN_OPTUM_REFERRAL_UTILITY' = 'open-optum-referral-utility',
  'CLOSE_OPTUM_REFERRAL_UTILITY' = 'close-optum-referral-utility',
  'ON_SUBMIT_REFERRAL' = 'on-submit-referral',
  'HIDE_OVERLAY' = 'hide-overlay',
}

export enum IncomingEventType {
  UPDATE_BUTTON_VISIBILITY = 'update-button-visibility',
  GET_URL = 'get-url',
  SET_SCREEN = 'set-screen',
  SET_TO_INITIAL_STATE = 'set-to-initial-state',
  NOTIFICATION = 'notification',
}

export enum WidgetIncomingEventName {
  AccessTokenChanged = 'AccessTokenChanged',
  PatientInContext = 'patientInContext',
  PatientOutOfContext = 'patientOutOfContext',
  EncounterStart = 'encounterStart',
  EncounterExit = 'encounterExit',
  PatientChartOpened = 'patientChartOpened',
  PatientChartClosed = 'patientChartClosed',
}

export enum RuntimeOutgoingEvents {
  WriteBack = 'vim-connect-write-back',
}

export interface IncomingActionPayload extends Record<IncomingEventType, any> {
  [IncomingEventType.UPDATE_BUTTON_VISIBILITY]: {
    display: boolean;
    shouldDisabled: boolean;
    isLoading: boolean;
  };
  [IncomingEventType.GET_URL]: {
    url: string;
  };
  [IncomingEventType.SET_SCREEN]: {
    mode: Screens;
  };
  [IncomingEventType.SET_TO_INITIAL_STATE]: undefined;
}

export interface IncomingAction<T extends IncomingEventType> {
  type: T;
  payload: IncomingActionPayload[T];
}

export interface Address {
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zipCode?: string;
}

export interface PatientDemographics {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  address: Address;
}

export interface MemberEligibility {
  plan: string;
  groupNumber: string;
}

export interface Insurance {
  insurer: Insurer;
  memberId: string;
  groupId?: string;
}

interface Diagnosis {
  description: string;
  code: string;
}

interface ProviderDemographics {
  firstName: string;
  lastName: string;
  middleName?: string;
}

export interface Patient {
  demographics: PatientDemographics;
  patientId: string;
  insurance: Insurance;
  eligibility?: MemberEligibility;
  problemList?: Diagnosis[];
}

interface Provider {
  providerEhrId: string;
  npi?: string;
  demographics: ProviderDemographics;
  specialty?: string;
}

export interface Encounter {
  encounterDate: string;
  encounterId: string;
  provider: Provider;
}

export interface LaunchUrlRequest {
  patientId?: string;
  address?: string;
  firstName?: string;
  lastName?: string;
  dateOfBirth?: string;
  launchType?: ApplicationLaunchType;
  subscriberNumber?: string;
  groupNumber?: string;
  problemList?: Diagnosis[];
  providerId?: string;
  encounterId?: string;
  encounterDateTime?: string;
}

export interface GareGapsRequest {
  patientId: string;
  firstName?: string;
  lastName?: string;
  dateOfBirth?: string;
}

export type GareGaps = {
  conditions: number;
  insufficient: number;
  quality: number;
  total: number;
};

export interface WritebackUrlRequest {
  cacheKey: string;
}

export enum StatusType {
  Agree = 'Agree',
  Disagree = 'Disagree',
}

export interface EhrWritebackDiagnosis {
  code: string;
  description: string;
  note?: string;
}

export interface WritebackDiagnosis {
  description: string;
  code: string;
  status: StatusType;
}

export interface WritebackUrlResponse {
  diagnosis: WritebackDiagnosis[];
}
