import { ModuleNames, useVimCommunication } from '@getvim/vim-app-infra';
import { Common, WriteBacks } from '@getvim/vim-connect';
import { EhrWritebackDiagnosis, RuntimeOutgoingEvents } from '../types';

const WRITE_BACK_TIMEOUT = 20000;

export const useWriteBack = () => {
  const { vimCommunication } = useVimCommunication();

  const updateEncounter = async (diagnosis: EhrWritebackDiagnosis[]): Promise<boolean> => {
    let writeBackData;

    try {
      const data: WriteBacks.UpdateEncounter = { addedDiagnoses: diagnosis, addedProcedures: [] };

      writeBackData = await Common.timeoutAfter(
        async () => {
          await vimCommunication?.sendSyncEvent(
            ModuleNames.Runtime,
            RuntimeOutgoingEvents.WriteBack,
            {
              payload: {
                writeBack: 'updateEncounter',
                data,
                config: {
                  displayLoadingWidget: true,
                  loadingWidgetId: 'optumDataCore',
                },
              },
            },
          );

          return { success: true };
        },
        WRITE_BACK_TIMEOUT,
        true,
      );
    } catch (err: any) {
      return false;
    }

    return writeBackData.success;
  };

  const updateProblemList = async (diagnosis: EhrWritebackDiagnosis[]): Promise<boolean> => {
    let writeBackData;

    try {
      const data: WriteBacks.UpdateProblemList = {
        conditionsToDismiss: diagnosis,
        conditionsToAdd: [],
      };

      writeBackData = await Common.timeoutAfter(
        async () => {
          await vimCommunication?.sendSyncEvent(
            ModuleNames.Runtime,
            RuntimeOutgoingEvents.WriteBack,
            {
              payload: {
                writeBack: 'updateProblemList',
                data,
                config: {
                  displayLoadingWidget: true,
                  loadingWidgetId: 'optumDataCore',
                },
              },
            },
          );

          return { success: true };
        },
        WRITE_BACK_TIMEOUT,
        true,
      );
    } catch (err: any) {
      return false;
    }

    return writeBackData.success;
  };

  return { updateEncounter, updateProblemList };
};
