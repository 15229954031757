import { gql } from '@apollo/client';
import { optumApiGqlClient } from '../gql-client';
import { GareGaps, GareGapsRequest } from '../../types';

export const getOptumDataCoreCareGaps = async ({
  patientId,
  firstName,
  lastName,
  dateOfBirth,
}: GareGapsRequest): Promise<GareGaps | undefined> => {
  try {
    const { data } = await optumApiGqlClient.query({
      query: optumDataCoreGetGapsCountQuery,
      variables: {
        input: {
          patientId: String(patientId),
          patientFirstName: firstName,
          patientLastName: lastName,
          patientDob: dateOfBirth,
        },
      },
      fetchPolicy: 'no-cache',
    });
    const { gaps } = data.optumDataCoreGetGapsCount;

    if (!gaps) {
      return undefined;
    }
    return {
      conditions: gaps?.hccGaps?.open,
      insufficient: gaps?.hccGaps?.insufficient,
      quality: gaps?.qualityGaps?.open,
      total: gaps?.open,
    };
  } catch (error) {
    console.log('Failed to fetch query', { error });

    return undefined;
  }
};

const optumDataCoreGetGapsCountQuery = gql`
  query OptumDataCoreGetGapsCount($input: OptumDataCoreGetGapsCountInput!) {
    optumDataCoreGetGapsCount(input: $input) {
      gaps {
        open
        hccGaps {
          open
          insufficient
        }
        qualityGaps {
          open
        }
      }
    }
  }
`;
