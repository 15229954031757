import { gql } from '@apollo/client';
import { gqlClient } from '../gql-client';
import { LaunchUrlRequest } from '../../types';

export const getOptumProLaunchUrl = async ({
  patientId,
  address,
  firstName,
  lastName,
  dateOfBirth,
}: LaunchUrlRequest): Promise<{ url: string } | undefined> => {
  try {
    const { data } = await gqlClient.query({
      query: optumProGetLaunchUrlQuery,
      variables: { input: { patientId, address, firstName, lastName, dateOfBirth } },
      fetchPolicy: 'no-cache',
    });
    return { url: data.optumProGetLaunchUrl.url };
  } catch (error) {
    console.log('Failed to fetch query', { error });

    return undefined;
  }
};

const optumProGetLaunchUrlQuery = gql`
  query OptumProGetLaunchUrl($input: OptumProGetLaunchUrlInput!) {
    optumProGetLaunchUrl(input: $input) {
      url
    }
  }
`;
