import { AnalyticsClient } from '@getvim/utils-vim-connect-communication';

export class OptumAnalyticsClient {
  constructor(private readonly widgetId?: string, private analyticsClient?: any) {
    this.widgetId = widgetId;
    this.analyticsClient = new AnalyticsClient(widgetId);
  }

  public trackLaunchUrlError() {
    this.analyticsClient.track(`${this.widgetId}_launch_url_error`, { widgetId: this.widgetId });
  }

  public trackLaunchUrlLoaded() {
    this.analyticsClient.track(`${this.widgetId}_launch_url_loaded`, { widgetId: this.widgetId });
  }

  public trackOptumNotificationsWidgetOpened() {
    this.analyticsClient.track(`${this.widgetId}_opened`, { widgetId: this.widgetId });
  }

  public trackOptumNotificationsWidgetDismissClick() {
    this.analyticsClient.track(`${this.widgetId}_dismiss_click`, { widgetId: this.widgetId });
  }

  public trackCareGapsError() {
    this.analyticsClient.track(`${this.widgetId}_care_gaps_error`, { widgetId: this.widgetId });
  }

  public trackCareGapsLoaded() {
    this.analyticsClient.track(`${this.widgetId}_care_gaps_loaded`, { widgetId: this.widgetId });
  }
}
