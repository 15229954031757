import { gql } from '@apollo/client';
import { optumApiGqlClient } from '../gql-client';
import { WritebackUrlRequest, WritebackUrlResponse } from '../../types';

export const getOptumDataCoreWriteback = async ({
  cacheKey,
}: WritebackUrlRequest): Promise<WritebackUrlResponse | undefined> => {
  try {
    const { data } = await optumApiGqlClient.query({
      query: optumDataCoreGetWritebackQuery,
      variables: {
        input: {
          cacheKey,
        },
      },
      fetchPolicy: 'no-cache',
    });

    return { diagnosis: data.optumDataCoreGetWriteback.data };
  } catch (error) {
    console.log('Failed to fetch query', { error });

    return undefined;
  }
};

const optumDataCoreGetWritebackQuery = gql`
  query OptumDataCoreGetWriteback($input: OptumDataCoreGetWritebackInput!) {
    optumDataCoreGetWriteback(input: $input) {
      data {
        description
        code
        status
      }
    }
  }
`;
