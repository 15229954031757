import { useCallback } from 'react';
import { ModifyApplication } from '@getvim/vim-connect-communication';
import { Entities } from '@getvim/vim-connect';

export type ModifyAppViewStatePayload = {
  enable: boolean;
  modifications?: Partial<Entities.UIElements.VimConnectUIWidget>;
};

export const useModifyAppViewState = (): [
  (payload: ModifyAppViewStatePayload) => Promise<void>,
] => {
  const modify = useCallback(async (payload: ModifyAppViewStatePayload): Promise<void> => {
    const { enable, modifications } = payload;

    ModifyApplication.modify({
      disabled: !enable,
      loaded: true,
      ...modifications,
    });
  }, []);

  return [modify];
};
