import React, { FC, useEffect, useReducer } from 'react';
import './select-provider-iniline-widget.css';
import { registerWidget, sendDataToApp } from '@getvim/utils-vim-connect-communication';
import { Entities } from '@getvim/vim-connect';
import { IncomingAction, IncomingEventType, OutgoingEventType } from '../../types';
import { reducer, ButtonState } from '../reducer';
import OptimalCareImg from '../optimalCareImg/optimalCareImg';

const initialState: ButtonState = {
  display: true,
  shouldDisabled: true,
  isLoading: true,
};

const SelectProviderInlineWidget: FC = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const { display, shouldDisabled, isLoading } = state;

  useEffect(() => {
    registerWidget((data: IncomingAction<IncomingEventType>) => {
      console.log('Data arrived from app:', { data });

      dispatch(data);
    });
  }, []);

  return display ? (
    <div>
      {isLoading ? (
        <div className="vim-loading-id widget-inline-size">
          <img src="https://static.getvim.com/img/dotsLoader.svg" alt="loading logo" />
        </div>
      ) : (
        <button
          type="button"
          className="vim-referral-utility-button"
          disabled={shouldDisabled}
          onClick={() => {
            sendDataToApp({
              event: OutgoingEventType.OPEN_OPTUM_REFERRAL_UTILITY,
              data: { expandingType: Entities.UIElements.ExpandingType.VIM_APP_MANUAL },
            });
          }}
        >
          <OptimalCareImg />
        </button>
      )}
    </div>
  ) : null;
};

export default SelectProviderInlineWidget;
