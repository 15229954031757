import { gql } from '@apollo/client';
import { optumApiGqlClient } from '../gql-client';
import { LaunchUrlRequest } from '../../types';
import { optumDateFormatter } from '../../utils/formatter';

export const getOptumDataCoreLaunchUrl = async ({
  patientId,
  firstName,
  lastName,
  dateOfBirth,
  launchType,
  problemList,
  providerId,
  encounterId,
  encounterDateTime,
}: LaunchUrlRequest): Promise<{ url: string; cacheKey: string } | undefined> => {
  try {
    const { data } = await optumApiGqlClient.query({
      query: optumDataCoreGetLaunchUrlQuery,
      variables: {
        input: {
          patientId: String(patientId),
          patientFirstName: firstName,
          patientLastName: lastName,
          dateOfBirth: optumDateFormatter(dateOfBirth!),
          launchType,
          problemList,
          providerId,
          encounterId,
          encounterDateTime,
        },
      },
      fetchPolicy: 'no-cache',
    });

    return {
      url: data.optumDataCoreGetLaunchUrl.url,
      cacheKey: data.optumDataCoreGetLaunchUrl.cacheKey,
    };
  } catch (error) {
    console.log('Failed to fetch query', { error });

    return undefined;
  }
};

const optumDataCoreGetLaunchUrlQuery = gql`
  query OptumDataCoreGetLaunchUrl($input: OptumDataCoreGetLaunchUrlInput!) {
    optumDataCoreGetLaunchUrl(input: $input) {
      url
      cacheKey
    }
  }
`;
