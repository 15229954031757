import { gql } from '@apollo/client';
import { optumApiGqlClient } from '../gql-client';
import { LaunchUrlRequest } from '../../types';

export const getOptumDataCoreDashboardLaunchUrl = async ({
  launchType,
}: LaunchUrlRequest): Promise<{ url: string; cacheKey: string } | undefined> => {
  try {
    const { data } = await optumApiGqlClient.query({
      query: optumDataCoreGetLaunchUrlQuery,
      variables: {
        input: {
          launchType,
        },
      },
      fetchPolicy: 'no-cache',
    });

    return {
      url: data.optumDataCoreGetLaunchUrl.url,
      cacheKey: data.optumDataCoreGetLaunchUrl.cacheKey,
    };
  } catch (error) {
    console.log('Failed to fetch query', { error });

    return undefined;
  }
};

const optumDataCoreGetLaunchUrlQuery = gql`
  query OptumDataCoreGetLaunchUrl($input: OptumDataCoreGetLaunchUrlInput!) {
    optumDataCoreGetLaunchUrl(input: $input) {
      url
      cacheKey
    }
  }
`;
