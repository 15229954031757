import React, { FC } from 'react';

const OptimalCareImg: FC = () => (
  <img
    src="https://static.getvim.com/img/logos/optimal-care-logo.png"
    alt="OptimalCare"
    title="OptimalCare"
  />
);

export default OptimalCareImg;
