import { useCallback, useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { Notifications } from '@getvim/vim-connect-communication';
import { ModuleNames } from '@getvim/vim-app-infra';
import { GareGaps } from '../types';
import { useAppEventsHandler } from './useAppEventsHandler';

const getProviderMessage = (gaps: GareGaps) => {
  return `<div id=${uuid()}>Chronic conditions: ${gaps?.conditions}</div> <div>Insufficient: ${
    gaps?.insufficient
  }</div> <div>Quality: ${gaps?.quality ?? 'N/A'}</div>`;
};

interface UseNotificationReturnValue {
  patientGaps: (gaps: GareGaps) => void;
}

export const useNotifications = (): UseNotificationReturnValue => {
  const [notification, setNotification] = useState<string | null>(null);
  const { patient } = useAppEventsHandler();

  useEffect(() => {
    if (notification && patient) {
      Notifications.Actions.showNotification({
        widgetId: ModuleNames.OptumDataCoreWidget,
        text: notification,
        notificationId: crypto.randomUUID(),
        dismissText: 'Dismiss',
        title: 'DataCORE Gaps',
        modifyWidgetParams: { size: { width: '320', height: '120' } },
        options: {
          timeoutInMs: 10000,
          vimConnectStyle: false,
          optumStyle: true,
        },
      });
      setNotification(null);
    }
  }, [notification, setNotification, patient]);

  const patientGaps = useCallback(
    (gaps: GareGaps) => {
      setNotification(getProviderMessage(gaps));
    },
    [setNotification],
  );

  return { patientGaps };
};
