import { IncomingAction, IncomingEventType } from './types';

export const validateSetScreenType = (
  data: IncomingAction<IncomingEventType>,
): data is IncomingAction<IncomingEventType.SET_SCREEN> =>
  data.type === IncomingEventType.SET_SCREEN;

export const validateGetUrlType = (
  data: IncomingAction<IncomingEventType>,
): data is IncomingAction<IncomingEventType.GET_URL> => data.type === IncomingEventType.GET_URL;

export const validateSetInitialStateType = (
  data: IncomingAction<IncomingEventType>,
): data is IncomingAction<IncomingEventType.SET_TO_INITIAL_STATE> =>
  data.type === IncomingEventType.SET_TO_INITIAL_STATE;

export const validateUpdateButtonVisibilityType = (
  data: IncomingAction<IncomingEventType>,
): data is IncomingAction<IncomingEventType.UPDATE_BUTTON_VISIBILITY> =>
  data.type === IncomingEventType.UPDATE_BUTTON_VISIBILITY;