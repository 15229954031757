import { VimConnectGqlClient } from '@getvim/components-vim-connect-gql-client';
import { getAccessToken } from '@getvim/vim-app-infra';
import { referralUtilityWidgetId } from '../consts';

export const gqlClient = new VimConnectGqlClient({
  // TODO: will be removed in the future
  apiUrl: VimConnectGqlClient.getHttpLinkUrl('/api/graphql', referralUtilityWidgetId),
  accessTokenCallback: getAccessToken,
});

export const optumApiGqlClient = new VimConnectGqlClient({
  apiUrl: VimConnectGqlClient.getHttpLinkUrl('/optum/api/graphql', referralUtilityWidgetId),
  accessTokenCallback: getAccessToken,
});
