import { FeatureFlagProvider } from '@getvim/feature-flags-react';
import { featureFlagsConfig } from './utils/featureFlags';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

ReactDOM.render(
  <React.StrictMode>
    <FeatureFlagProvider {...featureFlagsConfig}>
      <App />
    </FeatureFlagProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// clear commit - can be removed any time
