import {
  getOptumProLaunchUrl,
  getOptumDataCoreLaunchUrl,
  getOptumDataCoreCareGaps,
  getOptumDataCoreDashboardLaunchUrl,
  getOptumDataCoreWriteback,
} from './actions';

export const apiClient = {
  getOptumProLaunchUrl,
  getOptumDataCoreLaunchUrl,
  getOptumDataCoreCareGaps,
  getOptumDataCoreDashboardLaunchUrl,
  getOptumDataCoreWriteback,
};
